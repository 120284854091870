import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
var _templateObject;
import { useI18next } from "gatsby-plugin-react-i18next";
import { useEffect, useState } from "react";
import { ContentfulSpace } from "../constant";
var getGraphqlLocaleMobile = function getGraphqlLocaleMobile(language) { switch (language) {
    case "en": return "en-US";
    case "cn": return "zh-Hans-CN";
    case "hk":
    default: return "zh-Hant-HK";
} };
var getGraphqlLocaleWebsite = function getGraphqlLocaleWebsite(language) { switch (language) {
    case "en": return "en-HK";
    case "cn": return "zh-Hans-HK";
    case "hk":
    default: return "zh-Hant-HK";
} }; /**
 *
 * Custom hook to fetch GraphQL query to contentful host.
 * <T> return data type, default will be any.
 */
var useContentfulQueryHook = function useContentfulQueryHook(props) {
    var _useState = useState(null), data = _useState[0], setData = _useState[1];
    var _useI18next = useI18next(), language = _useI18next.language;
    var spaces = props.spaces, query = props.query, variables = props.variables, getGraphqlLocale = props.getGraphqlLocale;
    useEffect(function () {
        var _require = require("@apollo/client"), ApolloClient = _require.ApolloClient, InMemoryCache = _require.InMemoryCache, gql = _require.gql, HttpLink = _require.HttpLink;
        var getLink = function getLink() { switch (spaces) {
            case ContentfulSpace.MOBILE: return new HttpLink({ uri: "https://graphql.contentful.com/content/v1/spaces/" + process.env.GATSBY_MOBILE_CONTENTFUL_APP_SPACE_ID + "/environments/" + process.env.GATSBY_MOBILE_CONTENTFUL_APP_ENV, headers: { Authorization: "Bearer " + process.env.GATSBY_MOBILE_CONTENTFUL_APP_ACCESS_TOKEN } });
            case ContentfulSpace.WEBSITE: return new HttpLink({ uri: "https://graphql.contentful.com/content/v1/spaces/" + process.env.GATSBY_CONTENTFUL_SPACE_ID + "/environments/" + process.env.CONTENTFUL_ENV, headers: { Authorization: "Bearer " + process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN } });
            case ContentfulSpace.CUSTOM: return new HttpLink({ uri: "https://graphql.contentful.com/content/v1/spaces/" + props.spaceId + "/environments/" + props.contentfulEnv, headers: { Authorization: "Bearer " + props.accessToken } });
        } };
        var client = new ApolloClient({ link: getLink(), cache: new InMemoryCache() });
        var determineGetGraphqlLocale = function determineGetGraphqlLocale() { if (getGraphqlLocale)
            return getGraphqlLocale; return spaces === ContentfulSpace.WEBSITE ? getGraphqlLocaleWebsite : getGraphqlLocaleMobile; };
        var observable = client.watchQuery({ query: gql(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n        ", "\n      "])), query), variables: Object.assign({ locale: determineGetGraphqlLocale()(language) }, variables) });
        var subscription = observable.subscribe({ next: function next(response) {
                var data = response.data; // Handle the fetched data
                setData(data);
            }, error: function error(_error) { console.error(_error); } }); // clean up
        return function () { return subscription.unsubscribe(); };
    }, []);
    return data;
};
export default useContentfulQueryHook;
