import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux"; /**
 * Custom Hook to reset errorCode on alert dialog closed.
 * @param errorCodes reset if current errorCode is included
 * @see {@link useErrorCodeDetectHook}
 */
export function useResetErrorCodeOnCloseDialogHook(errorCodes) {
    var dispatch = useDispatch();
    var _useSelector = useSelector(function (state) { return state.ui; }), errorCode = _useSelector.errorCode, dialogOpen = _useSelector.dialogOpen;
    useEffect(function () {
        var isSubscribed = true;
        if (isSubscribed && !dialogOpen && errorCodes.includes(errorCode)) { // avoid flashing
            resetErrorCodeWithoutFlash(dispatch);
        } // clean up when unmounted
        return function () { isSubscribed = false; };
    }, [dialogOpen]);
}
export function resetErrorCodeWithoutFlash(dispatch) {
    setTimeout(function () {
        return dispatch({ type: "ui/setErrorCode", payload: "" // default code
        });
    }, 1000);
}
