import { globalHistory } from "@reach/router";
import { useI18next } from "gatsby-plugin-react-i18next";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeSystemLanguage } from "../../qnb/api/language";
export function useAnchorPopupListener() {
    var dispatch = useDispatch();
    var _useI18next = useI18next(), language = _useI18next.language;
    useEffect(function () {
        changeSystemLanguage(language);
    }, []);
    function handleRoute() { if (location.hash === "#forgot-password") {
        dispatch({ type: "ui/setDialogOpen", payload: { status: true, page: "forgetPassword" } });
    } if (location.hash === "#login") {
        dispatch({ type: "ui/setDialogOpen", payload: { status: true, page: "login" } });
    } if (location.hash === "#register") {
        dispatch({ type: "ui/setDialogOpen", payload: { status: true, page: "createAccount" } });
    } }
    useEffect(function () { handleRoute(); return globalHistory.listen(function () { handleRoute(); }); }, []);
}
