import { useLocation } from "@reach/router";
import { useEffect, useRef } from "react";
import { useSetDelayHook } from "../../qnb/hooks/useSetDelayHook";
import { scrollToWithBarOffset } from "../utils/scrollToOffset";
import useOnPageLoad from "./useOnPageLoad";
var defaultProps = { onScrollDelay: 1000 }; /**
 * Detect if the url contains anchor(#).
 * If so, scroll to that section on page load.
 *
 * @param {number} props.onScrollDelay - Time allowed for scroll to anchor once found
 */
var useScrollToAnchor = function useScrollToAnchor(props) {
    var _defaultProps$props = Object.assign({}, defaultProps, props), onScrollDelay = _defaultProps$props.onScrollDelay;
    var _useSetDelayHook = useSetDelayHook(onScrollDelay), setScrollDelay = _useSetDelayHook.setDelay;
    var scrolledRef = useRef(false);
    var location = useLocation();
    var hash = location.hash; // callback function to call when event triggers
    var onPageLoad = function onPageLoad() { if (hash && !scrolledRef.current) {
        var scrollAction = function scrollAction() { scrollToWithBarOffset(hash); scrolledRef.current = true; };
        onScrollDelay ? setScrollDelay(scrollAction) : scrollAction();
    } };
    useOnPageLoad(onPageLoad);
    useEffect(function () { scrollToWithBarOffset(location.hash); }, [location]);
};
export default useScrollToAnchor;
