import { useEffect } from "react"; /**
 * Custom Hook to detect if the document is completely loaded.
 * If so, invoke onPageLoad function.
 * Otherwise, invoke onPageLoad function upon "load" event.
 *
 * @param onPageLoad - callback function to call when event triggers
 */
var useOnPageLoad = function useOnPageLoad(onPageLoad) {
    useEffect(function () {
        if (document.readyState === "complete") {
            onPageLoad();
        }
        else {
            window.addEventListener("load", onPageLoad, false); // clean up
            // Remove the event listener when component unmounts
            return function () { return window.removeEventListener("load", onPageLoad); };
        }
    }, []);
};
export default useOnPageLoad;
