import "core-js/modules/es.object.from-entries.js";
export default function utmCookie() {
    var abortController = new AbortController();
    var isBrowser = typeof window !== "undefined";
    var utmQueryList = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];
    var facebookSource = "facebook"; //   const utmQueryArr = window.location.search
    //     .replace(/\?/g, "")
    //     .split("&")
    //     .map((keyValuePair) =>
    //       keyValuePair.substring(0, keyValuePair.indexOf("="))
    //     );
    //   console.log(
    //     `%cutm cookies: %c${utmQueryArr}`,
    //     "color: red; font-weight: bold",
    //     "color: RebeccaPurple; font-weight: bold"
    //   );
    var url_obj = isBrowser ? Object.fromEntries(new URLSearchParams(window.location.search)) : {};
    if (!isBrowser) {
        return;
    }
    else {
        if (url_obj.utm_source == facebookSource) {
            if (typeof window !== "undefined") {
                if (window.fbq != null) {
                    window.fbq("trackCustom", "TestRedirect", { campaign: url_obj.utm_campaign, midium: url_obj.utm_medium, content: url_obj.utm_content }); // console.log(window.fbq('track', 'Lead', { currency: "USD", value: 9.99 }));
                    // console.log(fbq('trackCustom', 'ShareDiscount', {promotion: 'share_discount_10%'}));
                }
            }
        } // Clear broswer utm cookie to avoid duplicated previous pages
        (function () {
            var cookies = document.cookie.split("; "); // console.log(cookies);
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].replace(/ /g, "");
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
                var value = eqPos > -1 ? cookie.substring(eqPos + 1) : ""; //   console.log(`Value is ${!!value}`);
                // console.log(name.replace(/b_/, "") + " : " + value);
                if (utmQueryList.includes(name.replace(/b_/, "")) && value != null && value != "") { // console.log(name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;");
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
                }
            }
        })(); // Store current query string to browser cookies
        var cookieArr = document.cookie.split("; "); // console.log("cookieArr:", cookieArr);
        // cookieArr is ["a=apple; b=banana; w=watermelon"]
        var cookieStr = "";
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("="); // cookiePair is ["a", "apple"], ["b" = "banana"] ......
            // console.log("Cookie pair:", cookiePair);
            // console.log("Cookie name:", cookiePair[0]);
            // console.log("Cookie value:", cookiePair[1]);
            // No affecting non-utm cookies of Blue website
            if (utmQueryList.includes(cookiePair[0].replace(/b_/, ""))) { // if cookie stored is blank
                if (cookiePair[1] !== "" && cookiePair[1] != null) {
                    if (i === 0 || i === cookieArr.length) {
                        cookieStr += cookiePair[0] + "=" + cookiePair[1];
                    }
                    else {
                        cookieStr += ";" + cookiePair[0] + "=" + cookiePair[1];
                    }
                }
                if (cookiePair[1].replace(/ /g, "") === "") {
                    document.cookie = cookiePair[0] + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
                }
            }
            document.cookie = cookieStr; // console.log("Cookie string:", cookieStr);
            //Clear duplicated cookies, e.g. b_utm_source=&b_utm_medium=&b_utm_source=&b_utm_medium=
            var queryObject = isBrowser ? Object.fromEntries(new URLSearchParams(window.location.search)) : {}; // console.log("queryObject:", queryObject);
            for (var queryPair in queryObject) {
                if (utmQueryList.includes(queryPair)) {
                    if (queryObject[queryPair].replace(/ /g, "") == "") {
                        document.cookie = queryPair + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;";
                    }
                    else {
                        document.cookie = "b_" + queryPair + "=" + queryObject[queryPair];
                    }
                }
            }
        }
    }
    return function () {
        abortController.abort(); // return Object.fromEntries(new URLSearchParams(window.location.search));
    };
}
