import { useEffect, useState } from "react";
import { usePreviousStateHook } from "./usePreviousStateHook"; /**
 * Custom Hook to handle delay on invoke passed in function on {@link setDelay}.
 * @param delayMs Delay milliseconds
 */
export function useSetDelayHook(delayMs) {
    var _useState = useState(null), timeoutToken = _useState[0], setTimeoutToken = _useState[1];
    var prevTimeoutToken = usePreviousStateHook(timeoutToken); /**
      * additional flag for handling race condition between re-readState (still getting old state) and dispatch of setState
      */
    var _useState2 = useState(false), shouldClear = _useState2[0], setShouldClear = _useState2[1];
    useEffect(function () { if (shouldClear) {
        _clear(timeoutToken);
        setShouldClear(false);
    } }, [shouldClear]); /**
    * always clear for previous
    */
    useEffect(function () { _clear(prevTimeoutToken); }, [timeoutToken]); /**
    * Delay to invoke the passed in fn.
    * @param fn Actual function
    */
    var setDelay = function setDelay(fn) {
        var token = setTimeout(fn, delayMs); // assign token for cancel
        setTimeoutToken(token);
    }; /**
       * Remove the delay effect, i.e. stop invoking the passed in function in {@link setDelay}.
       */
    var clearDelay = function clearDelay() {
        _clear(timeoutToken);
        setShouldClear(true); // setup for race condition
    }; /**
       * private function to clearTimeout with type handling.
       */
    var _clear = function _clear(token) { if (token)
        clearTimeout(token); };
    return { setDelay: setDelay, clearDelay: clearDelay };
}
