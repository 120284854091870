import { useEffect, useState } from "react";
export function useWindowSize() {
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    var _useState = useState({ width: 0, height: 0 }), windowSize = _useState[0], setWindowSize = _useState[1];
    useEffect(function () {
        var abortController = new AbortController(); // Handler to call on window resize
        function handleResize() {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight });
        } // Add event listener
        window.addEventListener("resize", handleResize); // Call handler right away so state gets updated with initial window size
        handleResize(); // Remove event listener on cleanup
        return function () { window.removeEventListener("resize", handleResize); abortController.abort(); };
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}
